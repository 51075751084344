import React, { useCallback, useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { CRow, CCol, CCard, CCardBody, CCardHeader, CCardTitle, CLink, CButton, CCollapse } from '@coreui/react';
import Description from '../../components/Description';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { getPayoutRequest } from '../../services/BackendService';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { coalesce, formatCurrency, formatDateTime } from '../../helpers';
import { IAffiliateCommissionLedger } from './types';
import RejectPayoutRequestModal from './RejectPayoutRequestModal';
import EditCommissionLedgerModal from './EditCommissionLedgerModal';
import PaginationTable from '../../components/PaginationTable';
import AcceptPayoutRequestModal from './AcceptPayoutRequestModal';
import PayoutStatusBadge from './PayoutStatusBadge';

const PayoutRequest = () => {
	const [acceptModalShow, setAcceptModalShow] = useState<boolean>(false);
	const [rejectModalShow, setRejectModalShow] = useState<boolean>(false);
	const [ledgerEdit, setLedgerEdit] = useState<IAffiliateCommissionLedger | null>(null);
	const [page, setPage] = useState<number>(1);

	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const handleDetailsClicked = (id: number) => {
		history.push(`/commission-ledgers/${id}`);
	};

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const onPageChanged = (page: number) => {
		setPage(page);
	};

	const payoutRequestQuery = useQuery(['payout-request', offset, id], () => getPayoutRequest(id, limit, offset));
	const { isLoading, isError, data, refetch } = payoutRequestQuery;

	const onCommissionLedgerEditClicked = useCallback(
		(ledger: IAffiliateCommissionLedger) => {
			setLedgerEdit(ledger);
		},
		[setLedgerEdit]
	);

	const showAcceptModal = () => {
		setAcceptModalShow(true);
	};

	const hideAcceptModal = (fetchAgain?: boolean) => {
		setAcceptModalShow(false);
		if (fetchAgain) {
			refetch();
		}
	};

	const showRejectModal = () => {
		setRejectModalShow(true);
	};

	const hideEditLedgerModal = (fetchAgain?: boolean) => {
		setLedgerEdit(null);
		if (fetchAgain) {
			refetch();
		}
	};

	const hideRejectModal = (fetchAgain?: boolean) => {
		setRejectModalShow(false);
		if (fetchAgain) {
			refetch();
		}
	};

	const fields = useMemo(
		() => [
			{ key: 'id', label: 'ID', sorter: false },
			{ key: 'referral', label: _t('commissions.referral'), sorter: false },
			{ key: 'commissionType', label: _t('commissions.commission-type'), sorter: false },
			{ key: 'actionType', label: _t('commissions.action-type'), sorter: false },
			{ key: 'actionDetails', label: _t('commissions.action-details'), sorter: false },
			{ key: 'commissionAmount', label: _t('global.amount'), sorter: false },
			{ key: 'correctedCommissionAmount', label: _t('commissions.corrected-amount'), sorter: false },
			{ key: 'actionTimestamp', label: _t('global.date'), sorter: false },
			{ key: 'actions', label: _t('global.actions'), sorter: false },
			{ key: 'showDetails', label: _t('global.details'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			id: ({ id }: IAffiliateCommissionLedger) => (
				<td>
					<CLink href={`/commission-ledgers/${id}`}>{id}</CLink>
				</td>
			),
			referral: ({ referral }: IAffiliateCommissionLedger) => (
				<td>
					<CLink href={`/customers/${referral.id}`}>{referral.name}</CLink>
				</td>
			),
			actionTimestamp: ({ actionTimestamp }: IAffiliateCommissionLedger) => <td>{formatDateTime(actionTimestamp)}</td>,
			commissionAmount: ({ commissionAmount, currency }: IAffiliateCommissionLedger) => (
				<td>{formatCurrency(commissionAmount, currency)}</td>
			),
			correctedCommissionAmount: ({ correctedCommissionAmount, currency }: IAffiliateCommissionLedger) => (
				<td>{correctedCommissionAmount ? formatCurrency(correctedCommissionAmount, currency) : '-'}</td>
			),
			actions: (ledger: IAffiliateCommissionLedger) => (
				<td>
					<CButton
						color="primary"
						type="button"
						onClick={() => onCommissionLedgerEditClicked(ledger)}
						title="Edit commission ledger"
						size="sm"
						disabled={data?.payoutRequest.approvedAt !== null}
					>
						{_t('action.edit')}
					</CButton>
				</td>
			),
			showDetails: ({ id }: IAffiliateCommissionLedger) => (
				<td>
					<CButton
						color="primary"
						type="button"
						onClick={() => handleDetailsClicked(id)}
						title="Edit commission ledger"
						size="sm"
					>
						{_t('global.details')}
					</CButton>
				</td>
			),
		}),
		[onCommissionLedgerEditClicked, data?.payoutRequest.approvedAt]
	);

	if (isLoading || !data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={refetch} />;
	}

	const { payoutRequest, commissions, commissionsCount, totalPayoutAmount } = data;
	const { introducer, payoutWallet, requestedAt, approvedAt, rejectedAt, rejectReason, transactionId, admin } =
		payoutRequest;

	const showActionButtons = !approvedAt && !rejectedAt;

	return (
		<PageLayout
			title={_t('commissions.payout-request')}
			titleAppend={
				<div className="d-flex align-items-center justify-content-between" style={{ height: '36px' }}>
					<PayoutStatusBadge approvedAt={approvedAt} rejectedAt={rejectedAt} />
					<div>
						{showActionButtons && (
							<>
								<CButton onClick={showAcceptModal} className="mr-2" color="success">
									{_t('action.approve')}
								</CButton>
								<CButton onClick={showRejectModal} className="mr-2" color="danger">
									{_t('action.reject')}
								</CButton>
							</>
						)}
					</div>
				</div>
			}
		>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CRow>
								<Description title={_t('commissions.introducer')}>
									<CLink href={`/affiliates/${introducer.id}`}>{introducer.name}</CLink>
								</Description>
								<Description title={_t('commissions.payout-wallet')}>
									<CLink href={`/wallets/${payoutWallet.username}`}>{payoutWallet.username}</CLink>
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('commissions.requested-at')}>{formatDateTime(requestedAt)}</Description>
								<Description title={_t('commissions.action-date')}>
									{coalesce(approvedAt, rejectedAt) ? formatDateTime(coalesce(approvedAt, rejectedAt)) : '-'}
								</Description>
							</CRow>
							<CRow>
								<Description title={rejectedAt ? _t('commissions.rejected-by') : _t('commissions.approved-by')}>
									{admin ? <CLink href={`/customers/${admin.id}`}>{admin.name}</CLink> : '-'}
								</Description>
								<Description title={_t('commissions.payout-acceptance-transaction')}>
									{transactionId ? <CLink href={`/transactions/${transactionId}`}>{transactionId}</CLink> : '-'}
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('commissions.total-payout-amount')}>
									{formatCurrency(totalPayoutAmount)}
								</Description>
								<Description title={_t('commissions.number-of-commissions')}>{commissionsCount}</Description>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{rejectReason && (
				<CRow>
					<CCol>
						<CCard>
							<CCardHeader>
								<CCardTitle>{_t('commissions.reject-reason')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<p>{rejectReason}</p>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			)}

			{rejectedAt !== null && commissionsCount === 0 && (
				<CRow>
					<CCol>
						<CCard>
							<CCardHeader>
								<CCardTitle>{_t('global.info')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<p>{_t('commissions.rejected-request-info')}</p>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			)}

			{!(rejectedAt !== null && commissionsCount === 0) && (
				<CRow>
					<CCol md={12}>
						<CCard>
							<CCardHeader className="d-flex justify-content-between align-items-center">
								<CCardTitle>{_t('commissions.affiliate-commission-ledgers')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<PaginationTable
									data={commissions ?? []}
									tableFields={fields}
									scopedSlots={scopedSlots}
									loading={isLoading}
									pages={data.commissionsCount ? Math.ceil(data.commissionsCount / Number(limit)) : 1}
									activePage={page}
									onPageChanged={onPageChanged}
									pagination
								/>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			)}

			<AcceptPayoutRequestModal
				id={id}
				show={acceptModalShow}
				hidePanel={hideAcceptModal}
				acceptedAmount={totalPayoutAmount}
			/>
			<RejectPayoutRequestModal id={id} show={rejectModalShow} hidePanel={hideRejectModal} />
			<EditCommissionLedgerModal ledger={ledgerEdit} show={Boolean(ledgerEdit)} hidePanel={hideEditLedgerModal} />
		</PageLayout>
	);
};

export default PayoutRequest;
