import { useContext, useEffect, useReducer, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { AppState } from '../reducers';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
	updatePositionCacheFromWSMessage,
	updatePositionsCacheFromWSMessage,
	updatePositionsFromPositionClose,
	updatePositionsFromPositionStatus,
} from './positionHelpers';
import { IWallet } from '../pages/wallets/types';
import { IAccountStatusMessage, IPriceUpdateMessage } from './types';
import { isSet } from '../helpers';
import { IInstrument, InstrumentCategory } from '../services/ImportTypes';
import { ListenersEnum, SubscriptionTopicEnum, WebSocketContext } from '../services/WebsocketContext';

export const useLegacyState = <D>(initialState: D) =>
	useReducer((state: object, update: any) => ({ ...state, ...update }), initialState);

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const useQueryParams = () => {
	const [queryParams, setQueryParams] = useState<URLSearchParams | null>(null);
	const location = useLocation();
	useEffect(() => {
		setQueryParams(new URLSearchParams(location.search));
	}, [location.search]);

	return queryParams;
};

export const useHeartbeat = () => {
	const token = useAppSelector((state) => state.user.token);
	const wsContext = useContext(WebSocketContext);
	useEffect(() => {
		if (!wsContext) {
			return;
		}
		const { sendPing } = wsContext;
		const interval = setInterval(() => {
			sendPing(token!);
		}, 3000);
		return () => clearInterval(interval);
	}, [token]);
};

export const useLiveAccountsUpdates = () => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();
	const wsContext = useContext(WebSocketContext);

	useEffect(() => {
		if (!wsContext) {
			return;
		}
		const { subscribe, unsubscribe, addListener, removeListener } = wsContext;
		const accountsHandler = (message: IAccountStatusMessage) => {
			queryClient.setQueriesData(['all-wallets'], (oldData: any) => {
				if (!isSet(oldData) || !isSet(oldData.wallets)) {
					return oldData;
				}

				const foundWalletIndex = oldData.wallets.findIndex((w: any) => Number(w.username) === message.accountId);
				if (foundWalletIndex === -1) {
					return oldData;
				}

				const updatedWallets = [...oldData.wallets];
				updatedWallets[foundWalletIndex] = {
					...updatedWallets[foundWalletIndex],
					balance: message.balance,
					profitLoss: message.profitLoss,
					equity: message.equity,
					margin: message.margin,
				};
				return {
					pages: oldData.pages,
					wallets: updatedWallets,
				};
			});
		};

		subscribe(SubscriptionTopicEnum.ACCOUNTS, token ?? undefined);
		addListener(ListenersEnum.ACCOUNTS, accountsHandler);

		return () => {
			unsubscribe(SubscriptionTopicEnum.ACCOUNTS);
			removeListener(ListenersEnum.ACCOUNTS, accountsHandler);
		};
	}, [queryClient, token]);
};

export const useLiveAccountUpdates = (username: string, id: string) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();
	const wsContext = useContext(WebSocketContext);

	useEffect(() => {
		if (!wsContext) {
			return;
		}
		const { subscribe, unsubscribe, addListener, removeListener } = wsContext;
		const accountHandler = (message: IAccountStatusMessage) => {
			queryClient.setQueriesData(['customer-wallet', id], (oldData: any) => {
				if (!isSet(oldData)) {
					return oldData;
				}

				if (Number(username) === message.accountId) {
					return {
						performance: oldData.performance,
						wallet: {
							...oldData.wallet,
							equity: message.equity,
							margin: message.margin,
							marginFree: message.marginFree,
							profitLoss: message.profitLoss,
							balance: message.balance,
							credit: message.credit,
						},
					};
				}
				return oldData;
			});
		};

		subscribe(SubscriptionTopicEnum.ACCOUNTS, token ?? undefined);
		addListener(ListenersEnum.ACCOUNTS, accountHandler);

		return () => {
			unsubscribe(SubscriptionTopicEnum.ACCOUNTS);
			removeListener(ListenersEnum.ACCOUNTS, accountHandler);
		};
	}, [queryClient, token, username, id]);
};

export const useLiveOpenPositionUpdates = () => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();
	const wsContext = useContext(WebSocketContext);

	useEffect(() => {
		if (!wsContext) {
			return;
		}
		const { subscribe, unsubscribe, addListener, removeListener } = wsContext;
		const positionHandler = (message: any) => {
			queryClient.setQueriesData('open-positions', (oldData: any) =>
				updatePositionsCacheFromWSMessage(oldData, message)
			);
		};
		subscribe(SubscriptionTopicEnum.POSITIONS, token ?? undefined);
		addListener(ListenersEnum.POSITIONS, positionHandler);
		return () => {
			unsubscribe(SubscriptionTopicEnum.POSITIONS);
			removeListener(ListenersEnum.POSITIONS, positionHandler);
		};
	}, [queryClient, token]);
};

export const useLivePositionsUpdates = () => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();
	const wsContext = useContext(WebSocketContext);

	useEffect(() => {
		if (!wsContext) {
			return;
		}
		const { subscribe, unsubscribe, addListener, removeListener } = wsContext;
		const positionHandler = (message: any) => {
			queryClient.setQueriesData('all-positions', (oldData: any) =>
				updatePositionsCacheFromWSMessage(oldData, message)
			);
		};
		subscribe(SubscriptionTopicEnum.POSITIONS, token ?? undefined);
		addListener(ListenersEnum.POSITIONS, positionHandler);
		return () => {
			unsubscribe(SubscriptionTopicEnum.POSITIONS);
			removeListener(ListenersEnum.POSITIONS, positionHandler);
		};
	}, [queryClient, token]);
};



export const useLiveCustomerOpenPositionUpdate = (customerId: string, positionIds: Array<number>) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();
	const wsContext = useContext(WebSocketContext);

	useEffect(() => {
		if (!wsContext) {
			return;
		}
		const { subscribe, unsubscribe, addListener, removeListener } = wsContext;
		const positionsHandler = (message: any) => {
			const isMessageTypeOpen = message.messageType === 'PositionOpen';
			if (!positionIds.includes(message.positionId) && !isMessageTypeOpen) {
				return;
			}

			queryClient.setQueriesData(['customer-open-positions', customerId], (oldData: any) => {
				const { messageType, ...rest } = message;
				if (messageType === 'PositionOpen') {
					const wallets = queryClient.getQueryData<Array<IWallet>>(['customer-wallets', customerId]);
					if (wallets) {
						const walletsUsernames = wallets.map((w) => w.username);
						if (walletsUsernames.indexOf(message.accountId) !== -1) {
							const { positionId } = rest;
							const positions = [...oldData.positions];
							const position = { id: positionId, ...rest };

							positions.push(position);

							return {
								...oldData,
								positions,
							};
						}
					}
				}
				if (messageType === 'PositionStatus') {
					const positions = updatePositionsFromPositionStatus(oldData.positions, message);
					return {
						...oldData,
						positions,
					};
				}
				if (messageType === 'PositionClose') {
					const positions = updatePositionsFromPositionClose(oldData.positions, message);

					return {
						...oldData,
						positions,
					};
				}
				return oldData;
			});
		};

		subscribe(SubscriptionTopicEnum.POSITIONS, token ?? undefined);
		addListener(ListenersEnum.POSITIONS, positionsHandler);

		return () => {
			unsubscribe(SubscriptionTopicEnum.POSITIONS);
			removeListener(ListenersEnum.POSITIONS, positionsHandler);
		};
	}, [queryClient, token, customerId, positionIds]);
};

export const useLivePositionUpdates = (positionId: number) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();
	const wsContext = useContext(WebSocketContext);

	useEffect(() => {
		if (!wsContext) {
			return;
		}
		const { subscribe, unsubscribe, addListener, removeListener } = wsContext;
		const positionsHandler = (message: any) => {
			if (message.positionId !== positionId) {
				return;
			}

			queryClient.setQueriesData(['position', positionId], (oldData: any) => {
				return updatePositionCacheFromWSMessage(oldData, message);
			});
		};

		subscribe(SubscriptionTopicEnum.POSITIONS, token ?? undefined);
		addListener(ListenersEnum.POSITIONS, positionsHandler);

		return () => {
			unsubscribe(SubscriptionTopicEnum.POSITIONS);
			removeListener(ListenersEnum.POSITIONS, positionsHandler);
		};
	}, [queryClient, token, positionId]);
};

export const useLivePriceUpdate = (symbol: string) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();
	const wsContext = useContext(WebSocketContext);

	useEffect(() => {
		if (!wsContext) {
			return;
		};
		const { subscribe, unsubscribe, addListener, removeListener } = wsContext;
		const instrumentHandler = (message: IPriceUpdateMessage) => {
			queryClient.setQueriesData(['instrument', symbol], (oldData: any) => {
				if (oldData.symbol === message.symbol) {
					const { ask, bid } = message;
					const formattedPrice = ((ask + bid) / 2).toFixed(oldData.decimals);
					const newInstrument = { ...oldData };
					newInstrument.price = formattedPrice;
					return newInstrument;
				}

				return oldData;
			});

			queryClient.setQueriesData(['ask-bid', symbol], (oldData: any) => {
				if (oldData.symbol === message.symbol) {
					const { ask, bid } = message;
					return { ask, bid, symbol };
				}

				return oldData;
			});
		};

		subscribe(SubscriptionTopicEnum.PRICE, token ?? undefined);
		addListener(ListenersEnum.PRICE, instrumentHandler);
		return () => {
			unsubscribe(SubscriptionTopicEnum.PRICE);
			removeListener(ListenersEnum.PRICE, instrumentHandler);
		};
	}, [token, queryClient, symbol]);
};

export const useLivePriceUpdates = (activeCategory: InstrumentCategory) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();
	const wsContext = useContext(WebSocketContext);

	useEffect(() => {
		if (!wsContext) {
			return;
		}
		const { subscribe, unsubscribe, addListener, removeListener } = wsContext;
		const instrumentsHandler = (message: IPriceUpdateMessage) => {
			queryClient.setQueriesData(['instruments', activeCategory], (oldData: any) => {
				const index = oldData.findIndex((value: IInstrument) => value.symbol === message.symbol);
				if (index !== -1) {
					const { ask, bid } = message;
					const formattedPrice = ((ask + bid) / 2).toFixed(oldData[index].decimals);

					const instrumentWithPrice = {
						...oldData[index],
						price: formattedPrice,
					};

					const newInstruments = [...oldData];
					newInstruments[index] = instrumentWithPrice;

					return newInstruments;
				}
				return oldData;
			});
		};

		subscribe(SubscriptionTopicEnum.PRICE, token ?? undefined);
		addListener(ListenersEnum.PRICE, instrumentsHandler);
		return () => {
			unsubscribe(SubscriptionTopicEnum.PRICE);
			removeListener(ListenersEnum.PRICE, instrumentsHandler);
		};
	}, [token, queryClient, activeCategory]);
};
